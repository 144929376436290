














































































































































































import LookUp from '@/components/lookup/Index.vue'
import { authPermissions, genericErrorMessage } from '@/constants'
import { EventBus, ON_SEARCH_PAGE_CREATE, ON_SEARCH_PAGE_DELETE, ON_SEARCH_PAGE_EDIT, TRIGGER_SEARCH } from '@/eventbus'
import { userService } from '@/services'
import { Permission, Role } from '@/types/UserServiceType'
import { cloneDeep } from 'lodash'
import Vue from 'vue'

function generateForm() {
    return {
        role: {
            id: '',
            name: '',
            originalName: '',
            permissions: {} as Record<string, boolean>
        },
        dialog: false,
        loading: false,
        errors: {} as Record<string, string>,
        success: false,
        deleteDialog: false
    }
}

export default Vue.extend({
    name: 'UserRole',
    components: {
        LookUp
    },
    async mounted() {
        EventBus.$on(ON_SEARCH_PAGE_CREATE, (pageName:string) => {
            if(pageName === this.options.name) {
                this.onRoleCreate()
            }
            
        })
        
        EventBus.$on(ON_SEARCH_PAGE_EDIT, (pageName:string, payload: Role) => {
            if(pageName === this.options.name) { 
                this.onRoleEdit(payload)
            }
            
        })

        EventBus.$on(ON_SEARCH_PAGE_DELETE, (pageName:string, payload: Role) => {
            if(pageName === this.options.name) { 
                this.onRoleDelete(payload)
            }
            
        })
        
        await this.getAvailablePermissions()
    },
    destroyed() {
        EventBus.$off()
    },
    data(){
        return {
            options:{
                crudConfig: {
                    isUpdateable: true,
                    isCreateable: true,
                    isDeleteable: true
                },
                name: 'userRoles',
                api: '/User/GetRoles',
                exportAPI: {
                    api: '/User/ExportRoles',
                    requiredPermissions: [authPermissions.canExportAll]
                },
                pivotTableAPI: {
                    api:'',
                    params: {
                        source: ''
                    }},
                fields:[
                    {
                        'name': 'id',
                        'alias': 'Id',
                        'defaultOperator': 'exact match',
                        'type': 'string',
                        'dataTableOrder':1,
                    },
                    {
                        'name': 'name',
                        'alias': 'Name',
                        'defaultOperator': 'exact match',
                        'type': 'string',
                        'dataTableOrder':2,
                    },
                    {
                        'name': 'permissions',
                        'alias': 'Permissions',
                        'defaultOperator': 'contains',
                        'type': 'string',
                        'dataTableOrder':3,
                        'method': 'quickFilterList',
                        'sortable': false
                    },
                ],
                keywordSearchPlaceholder: 'Enter Id or Name'
            },
            availablePermissions: [] as Permission[],
            form: generateForm()
        }
    },
    methods: {
        generatePermissionsDict() {
            return this.availablePermissions.reduce<Record<string,boolean>>((prev, curr) => {
                prev[curr.name] = false
                return prev
            }, {} as Record<string, boolean>)
        },
        onPermissionUpdate(value: boolean, permission: Permission) {
            this.form.role.permissions[permission.name] = value
        },
        onFormCancel() {
            this.form = generateForm()
            this.form.role.permissions = this.generatePermissionsDict()
        },
        async onFormSave() {
            this.form.errors = {}
            this.form.success = false
            this.form.loading = true

            const temp = cloneDeep(this.form.role)
            
            try {
                await userService.upsertRole({...temp, normalizedName: '', permissions: Object.keys(temp.permissions).filter(o=>temp.permissions[o])})
                this.form.success = true
            } catch (error) {
                if(this.axios.isAxiosError(error) && error.response?.status == 400) {
                    this.form.errors = error.response?.data.errors
                } else {
                    this.form.errors = {
                        genericError: genericErrorMessage
                    }
                }
            } finally{ 
                this.form.loading = false
            }
            EventBus.$emit(TRIGGER_SEARCH)
        },
        onRoleCreate() {
            this.onFormCancel()
            this.form.dialog = true
        },
        async onRoleEdit(role: Role) {
            this.onFormCancel()
            this.form.role = { ...role, originalName: role.name, permissions: this.form.role.permissions }
            const rolesPermissions = await userService.getRolePermissions(role.id)
            rolesPermissions.forEach(permission => this.form.role.permissions[permission.name] = true)
            this.form.dialog = true
        },
        onRoleDelete(role: Role) {
            this.onFormCancel()
            this.form.role.id = role.id
            this.form.role.name = role.name
            this.form.deleteDialog = true
        },
        async onRoleDeleteConfirm() {
            this.form.errors = {}
            this.form.success = false
            this.form.loading = true
            
            try {
                await userService.deleteRole(this.form.role.id)
                this.form.success = true
            } catch (error) {
                this.form.errors = {
                    genericError: genericErrorMessage
                }
            } finally{ 
                this.form.loading = false
            }
            EventBus.$emit(TRIGGER_SEARCH)
        },
        async getAvailablePermissions() {
            this.availablePermissions = await userService.getAvailablePermissions()
            this.form.role.permissions = this.generatePermissionsDict()
        }
    },
    computed: {
        groupedPermissions(): any[] {
            const groupedItems = [] as any[]
            const size = 3
            for (let i = 0; i < this.availablePermissions.length; i += size) {
                groupedItems.push(this.availablePermissions.slice(i, i+size))
            }
            return groupedItems
        },
        errorMessage(): string {
            return Object.values<string>(this.form.errors).reduce<string>( (prev, message:string) => {
                return `${prev} ${message}`
            }, '') 
        }
    }
})
